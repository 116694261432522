<template>
  <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" />
  <div v-else class="flex flex-col justify-center items-center">
    <div
      class="mt-6 w-full hidden md:block lg:hidden justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <HeaderGlobal titlemenu1="Your Pet Records" titlemenu2="Friends' Pets" />
    <!-- v-if="petoverviewTab != 1 || petTab1.length > 0" -->
    <div
      class="w-full mt-2 pl-0 md:pl-10 pt-8 pb-8 text-left bg-lightBlue font-proximaMedium rounded-br-2xl"
      v-if="petoverviewTab == 1 && petTab1.length > 0"
    >
      <!-- <h3 class="mb-6 text-2xl">
        {{ $t("pet_record_overview_with_pets_title") }}
      </h3> -->
      <div v-if="petoverviewTab == 1 && petTab1.length > 0">
        <ul class="flex flex-wrap">
          <li
            class="w-max mx-8 mb-10 flex justify-start items-start"
            v-for="(pet, i) in petTab1"
            :key="i"
          >
            <PetoverviewEach :pet="pet" />
          </li>
        </ul>
      </div>
    </div>
    <div
      class="w-full mt-2 pl-0 md:pl-10 pt-8 pb-8 text-left bg-lightBlue font-proximaMedium rounded-br-2xl"
      v-if="petoverviewTab == 2 && petTab2.length > 0"
    >
      <div v-if="petoverviewTab == 2 && petTab2.length > 0">
        <ul class="flex flex-wrap">
          <li
            class="w-max mx-6 mb-10 flex justify-start items-start"
            v-for="(pet, i) in petTab2"
            :key="i"
          >
            <PetoverviewEachFriend :pet="pet" />
          </li>
        </ul>
      </div>
    </div>
    <div
      class="w-full mt-2 pl-0 md:pl-10 pt-8 pb-8 text-left bg-lightBlue font-proximaMedium rounded-br-2xl"
      v-if="petoverviewTab == 3"
    >
      <div v-if="petoverviewTab == 3 && !shomessage">
        <h1 class="mb-5 text-blue flex justify-center">Your Pets</h1>
        <ul class="flex flex-wrap">
          <li
            class="w-max mx-2 mb-10 flex justify-start items-start"
            v-for="(pet, i) in petTab3"
            :key="i"
          >
            <DeceasedEach :pet="pet" />
          </li>
        </ul>
        <div v-if="petTab4.length > 0">
          <hr class="w-11/12 mb-5" />
          <h1 class="mb-5 text-blue flex justify-center">Friends’ Pets</h1>
          <ul class="flex flex-wrap">
            <li
              class="w-max mx-2 mb-10 flex justify-start items-start"
              v-for="(pet, i) in petTab4"
              :key="i"
            >
              <DeceasedEach :pet="pet" />
            </li>
          </ul>
        </div>
      </div>
      <div
        v-if="petoverviewTab == 3 && shomessage"
        class="mt-10 px-2 text-sm font-proximaLight"
      >
        This is a record archive of deceased pets that belong to you or have
        been shared with you. No changes to the data can be made and the records
        don’t count towards the pet limit, but you can continue to add pictures
        to the gallery.
      </div>
    </div>
    <div
      v-if="petoverviewTab == 1 && petTab1.length == 0"
      class="w-full text-left font-proximaMedium"
    >
      <FormError :errors="errorMsg" />

      <div class="mt-20">
        <h3 class="mb-4 text-2xl">
          {{ $t("pet_record_overview_no_pets_title") }}
        </h3>
        <h5 class="mt-12 mb-1 text-sm font-proximaLight">
          {{ $t("pet_record_overview_no_pets_info_text") }}
        </h5>
      </div>
      <!--  <SelectOptions :dataList="petType" class="w-8/12 lg:w-6/12 mt-2" /> -->
    </div>
    <div
      v-if="petoverviewTab == 2 && petTab2.length == 0"
      class="w-full text-left font-proximaMedium"
    >
      <FormError :errors="errorMsg" />

      <div class="mt-20">
        <h3 class="mb-4 text-2xl">
          {{ $t("pet_record_overview_no_shared_pets_title") }}
        </h3>
        <div class="mt-20 mb-1 text-sm font-proximaLight">
          <SubmitButton
            @click.native="goToContact"
            class="ml-1"
            title="Connect with friends"
            background="bg-primary"
            paddingX="px-4"
            width="w-full md:w-5/12"
          />
        </div>
      </div>
      <!--  <SelectOptions :dataList="petType" class="w-8/12 lg:w-6/12 mt-2" /> -->
    </div>
    <div class="w-full flex justify-start">
      <div class="w-4/12 mt-6">
        <div class="relative" v-if="!petsNumber">
          <AddIcon
            v-if="petoverviewTab == 1"
            @click.native="showAddpets = !showAddpets"
            class="w-20 h-20 cursor-pointer"
          />
          <ModalsNewPet :show="showAddpets" @close="showAddpets = false">
            <!-- <SelectOptions
              slot="selectoption"
              class="w-full px-0 md:w-6/12 mt-2 outline border"
              :dataList="petType"
              :title="$t('pet_record_overview_no_pets_dropdown_list')"
              @selected="selected"
            /> -->
            <div
              slot="selectoption"
              class="w-full md:w-10/12 flex justify-center mb-0 font-proximaMedium"
            >
              <SelectInput
                :dataList="petType"
                @selected="selected"
                class="w-full md:w-6/12 mt-2"
              />
            </div>
            <SubmitButton
              slot="rightBtn"
              class="ml-1"
              :title="$t('global_next')"
              background="bg-success"
              paddingX="px-8"
              width="w-11/12 md:w-4/12 lg:w-4/12"
              @click.native="routeToNewpet"
            />
          </ModalsNewPet>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectInput from "../forms/selectinput/SelectSection.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import AddIcon from "../icons/AddIcon.vue";
import ModalsNewPet from "../modals/ModalsNewPet.vue";
import FormError from "../warning/FormError.vue";
import PetoverviewEach from "./PetoverviewEach.vue";
import PetoverviewEachFriend from "./PetoverviewEachFriend.vue";
import DeceasedEach from "./deceased/DeceasedEach.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import Loader from "../loader/Loader.vue";
import { getEmailSocialRegistration } from "../../services/authenticationService";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "signIn",
  name: "signInAfterLogin",
  components: {
    AddIcon,
    FormError,
    HeaderGlobal,
    ModalsNewPet,
    PetoverviewEach,
    PetoverviewEachFriend,
    DeceasedEach,
    Loader,
    SubmitButton,
    SelectInput,
  },
  data() {
    return {
      petType: [
        { id: 1, name: "Dog" },
        { id: 2, name: "Cat" },
        { id: 3, name: "Ferret" },
        { id: 4, name: "Bird" },
        { id: 5, name: "Other" },
      ],
      showAddpets: false,
      errorMsg: [],
      newpetRoute: "",
    };
  },
  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
    }),
    petoverviewTab() {
      console.log(this.$store.state.petoverviewTab);
      return this.$store.state.petoverviewTab;
    },
    petRec() {
      return this.$store.getters.petRecords;
    },
    petTab1() {
      const firstpet = this.petRecords[0];
      localStorage.setItem("firstpet", JSON.stringify(firstpet));
      return this.petRecords
        ? this.petRecords.filter((item) => item.type == 2 || item.type == 1)
        : [];
    },
    petTab2() {
      return this.petRecords.filter((item) => item.type == 3);
    },
    petTab3() {
      console.log(
        "///",
        this.petRecords.filter((item) => item.type == 4).length
      );
      return this.petRecords.filter((item) => item.type == 4);
    },
    goToContact() {
      this.$router.push({ path: "/contacts" });
    },
    shomessage() {
      return this.petRecords.filter((item) => item.type == 4).length == 0;
    },
    petTab4() {
      return this.petRecords.filter((item) => item.type == 5);
    },
    petsNumber() {
      return (
        Object.keys(
          this.petRecords.filter((item) => item.type == 1 || item.type == 2)
        ).length >= 10
      );
    },
    existPets() {
      return this.petRecords.length > 0;
    },
    showLoaderComp() {
      return this.$store.state.showLoaderState;
    },
  },
  watch: {
    existPets(i) {
      this.$emit("existPet", i);
    },
  },
  async mounted() {
    let datato = {};
    this.$store.commit("showLoader", true);
    if (!localStorage.getItem("email")) {
      console.log("**********");
      if (this.$route.params["code7"] && this.$route.params["code8"]) {
        console.log("-------- google ----------");
        const urlSocial =
          this.$route.params["code7"] +
          "/" +
          this.$route.params["code8"] +
          "/" +
          window.location.search;

        await getEmailSocialRegistration(urlSocial)
          .then((result) => {
            this.signInAfterLogin(result.data).then(() => {
              this.getPetRecords(localStorage.getItem("email"));
            });
          })
          .catch((error) => {
            if (error.response && error.response.status == 404) {
              this.$router.push("/");
              console.log("User Not registered");
            }
            if (error.response && error.response.status == 401) {
              console.log("Email address registered using anothe provider");
            }
            if (error.response && error.response.status == 403) {
              console.log("INACTIVE ACCOUNT");
            }
          });
      } else if (this.$route.params["code2"] != "new") {
        console.log("---------- magic link -----------------");
        let code = this.$route.params["code2"];
        console.log("---------- magic link -----------------", code);
        this.signIn(code)
          .then(() => {
            console.log("---------- magic link 1 -----------------");
            this.getPetRecords(localStorage.getItem("email"));
            console.log(
              "---------- magic link 2 -----------------",
              localStorage.getItem("email")
            );
          })
          .catch((error) => {
            this.$router.push({ name: "Join" });
          });
      }
    } else {
      //console.log("------------------");
      this.getPetRecords(localStorage.getItem("email"));
    }
    /* else if(this.$route.params["code2"] == "news" &&
      localStorage.getItem("email")){
     
    } */
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signInAfterLogin: "auth/signInAfterLogin",
      getPetRecords: "petRecord/getPetRecords",
    }),
    addPet() {
      console.log("add pet");
    },
    selected(i) {
      this.newpetRoute = i;
    },
    routeToNewpet() {
      //1	dog	2	cat 3	ferret	4	bird	5	other
      if (this.newpetRoute == "Bird") {
        localStorage.setItem("species", 4);
        this.$router.push({ name: "NewPet" });
      } else if (this.newpetRoute == "Cat") {
        localStorage.setItem("species", 2);
        this.$router.push({ name: "NewPet" });
      } else if (this.newpetRoute == "Dog") {
        localStorage.setItem("species", 1);
        this.$router.push({ name: "NewPet" });
      } else if (this.newpetRoute == "Ferret") {
        localStorage.setItem("species", 3);
        this.$router.push({ name: "NewPet" });
      } else {
        localStorage.setItem("species", 5);
        this.$router.push({ name: "NewPet" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selectpets:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid white;
  left: -8px;
  top: 40px;
}
</style>
