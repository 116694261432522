<template>
  <div class="flex flex-col items-center cursor-pointer relative">
    <img
      class="rounded-full w-24 h-24 grayscale"
      style="filter: grayscale(60%)"
      :src="pet.profile_picture"
      :alt="pet.callName"
    />
    <span class="pt-2 text-sm font-proximaMedium">{{ petu.callName }}</span>
    <span class="text-sm font-proximaLight"
      >{{ petu.dateOfBirth }} - {{ petu.dateOfDeath }}</span
    >
    <!--  <ModalsPetStatus :show="modals" @close="modals = false" /> -->
  </div>
</template>

<script>
import ModalsPetStatus from "../../modals/ModalsPetStatus.vue";

export default {
  props: ["pet"],
  components: {
    ModalsPetStatus,
  },
  data() {
    return {
      dropdown: false,
      modals: false,
      transition: [],
      petu: this.pet,
    };
  },

  methods: {
    ddfalse() {
      this.dropdown = false;
    },
  },
};
</script>

<style></style>
