<template>
  <div class="flex flex-col items-center cursor-pointer relative">
    <div
      class="flex flex-col items-center cursor-pointer relative"
      @click="routeTo(petu)"
    >
      <img
        class="rounded-full w-24 h-24"
        :style="{ border: `4px solid ${pet.color}` }"
        :src="pet.profile_picture"
        :alt="pet.callName"
      />

      <span class="pt-3 text-md font-proximaLight"
        >{{ pet.callName }}
        <img
          v-if="pet.VerificationStatus"
          class="absolute right-0 bottom-4 ml-2"
          rel="prefetch"
          src="@/assets/images/friend-approval.png"
          alt="edit-icon"
      /></span>
    </div>

    <!--  <div class="bg-primary rounded-full p-1 absolute bottom-6 right-0">
      <component
        :is="whichIcon"
        v-if="petu.current_status != 100"
        width="26"
        height="26"
        iconColor="#fff"
      />
    </div> -->

    <div class="absolute -left-4">
      <img
        @click="dropdown = !dropdown"
        v-click-outside="ddfalse"
        class="h-4 cursor-pointer"
        src="@/assets/images/hamburger-icon.png"
        alt="hamburger-icon"
      />

      <div
        v-if="dropdown"
        class="w-44 mt-2 py-6 bg-white rounded-tr-lg z-10 relative"
      >
        <!--  <h5 class="pl-4 pb-2" @click="goTo('RecentActivity', petu)">
          {{ $t("pet_dropdown_menu_recent_activity") }}
        </h5> -->
        <ul>
          <li
            v-if="role == 1 || role == 3 || role == 6"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
            @click="goTo('SharingSetting', petu)"
          >
            {{ $t("pet_dropdown_menu_sharing_setting") }}
          </li>

          <li
            v-if="role == 7"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
          >
            Training Record
            <!-- {{ $t("pet_dropdown_menu_sharing_setting") }} -->
          </li>
          <!--  friend -->
          <li
            v-if="role == 2"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
          >
            Health Log Input
            <!-- {{ $t("pet_dropdown_menu_sharing_setting") }} -->
          </li>

          <!-- <li
            v-if="role == 1 || role == 3 || role == 6 || role == 7"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
            @click="goTo('CareBlog', petu)"
          >
            {{ $t("pet_dropdown_menu_care_blog") }}
          </li> -->
          <li
            v-if="role == 5"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
          >
            Medical Record
            <!-- {{ $t("pet_dropdown_menu_sharing_setting") }} -->
          </li>

          <li
            v-if="role == 1 || role == 3 || role == 4 || role == 6 || role == 7"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
            @click="goTo('MedicalHistoryDashboard', petu)"
          >
            Medical History
            <!-- {{ $t("pet_dropdown_menu_sharing_setting") }} -->
          </li>
          <li
            v-if="role == 2 || role == 4 || role == 5"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
            @click="goTo('CareBlog', petu)"
          >
            <!-- {{ $t("pet_dropdown_menu_care_blog") }} -->
            Care Blog Entry
          </li>

          <!--  <li v-if="role == 2"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange">
            {{ $t("pet_dropdown_menu_emergency_unlock") }}
          </li> -->
          <li
            v-if="role == 5"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
            @click="goTo('TransferOwnership', petu)"
          >
            {{ $t("pet_dropdown_menu_transfer_ownership") }}
          </li>

          <!--  <li
            v-if="role == 1 || role == 3 || role == 5 || role == 6"
            class="py-2 pl-4 cursor-pointer font-proximaLight border-transparent border-r-4 hover:bg-primary hover:text-white hover:border-r-4 hover:border-orange"
            @click="openPetStatusModals(petu)"
          >
            Change Pet Status
          </li> -->
        </ul>
      </div>
    </div>

    <!-- @current_status="getStatus" -->
    <ModalsPetStatus
      v-if="petforPetStatus.callName"
      :pet="petforPetStatus"
      :show="petStatusComp"
      @closePetStatus="modalsConfirmPetStatus = false"
    >
      <BorderedButton
        slot="leftBtn"
        class="mr-1"
        :title="$t('global_header_clear_status')"
        borderColor="border-mediumGrey"
        textCl="text-darkGrey"
        paddingX="px-8"
        @click.native="closePetStatusModals"
      />
      <SubmitButton
        slot="rightBtn"
        class="ml-1"
        :title="$t('global_confirm')"
        background="bg-success"
        paddingX="px-8"
        @click.native="submitForm(petforPetStatus)"
      />
    </ModalsPetStatus>
    <ModalsConfirmPetStatusVue
      v-if="petforPetStatus.callName"
      :show="modalsConfirmPetStatus"
      @closePetStatus="modalsConfirmPetStatus = false"
      :pet="petforPetStatus"
      :trans="petStatusDropdownComp.trans"
      :modalTitle="modalTitle"
      :modalBody="modalBody"
    />
    <ModalsDatePetDeath
      v-if="petforPetStatus.callName"
      :show="modalsPetDeath"
      @closePetDeath="modalsPetDeath = false"
      :pet="petforPetStatus"
      :transitionId="transitionId"
    />
  </div>
</template>

<script>
import { allowedPetRecordStatusTransition } from "../../services/petRecordStatusService";
import ModalsPetStatus from "../modals/ModalsPetStatus.vue";
import Status100Icon from "../icons/Status102Icon.vue";
import Status101Icon from "../icons/Status101Icon.vue";
import Status102Icon from "../icons/Status102Icon.vue";
import Status103Icon from "../icons/Status103Icon.vue";
import Status104Icon from "../icons/Status104Icon.vue";
import Status105Icon from "../icons/Status105Icon.vue";
import Status106Icon from "../icons/Status106Icon.vue";
import Status107Icon from "../icons/Status107Icon.vue";
import Status108Icon from "../icons/Status108Icon.vue";
import Status109Icon from "../icons/Status109Icon.vue";
import Status110Icon from "../icons/Status110Icon.vue";
import ModalsConfirmPetStatusVue from "../modals/ModalsConfirmPetStatus.vue";
import ModalsDatePetDeath from "../modals/ModalsDatePetDeath.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  props: { pet: {} },
  components: {
    ModalsPetStatus,
    ModalsConfirmPetStatusVue,
    ModalsDatePetDeath,
    SubmitButton,
    BorderedButton,
    Status100Icon,
    Status101Icon, //ForadoptIcon,
    Status102Icon, //ForsaleIcon,
    Status103Icon, //Status103Icon
    Status104Icon, //InboardingIcon
    Status105Icon, //IntransitIcon,
    Status106Icon, //Transfert Pending,
    Status107Icon,
    Status108Icon, //LostIcon,
    Status109Icon,
    Status110Icon, //DeceasedIcon,
  },
  data() {
    return {
      dropdown: false,
      modalsConfirmPetStatus: false,
      modalsPetDeath: false,
      petu: this.pet,
      modalTitle: "",
      modalBody: "",
      transitionId: 0,
      petforPetStatus: {},
    };
  },

  computed: {
    ...mapGetters({
      petRecords: "petRecord/getPetRecords",
      getError: "petRecord/getError",
    }),
    whichIcon() {
      return "Status" + this.pet.current_status + "Icon";
    },
    petStatusComp() {
      return this.$store.state.petStatusModalsState;
    },
    petStatusDropdownComp() {
      return this.$store.state.petStatusDropdownState;
    },
    role() {
      return localStorage.getItem("role");
    },
  },
  async created() {
    // this.getTransitions(this.petu.current_status);
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signInAfterLogin: "auth/signInAfterLogin",
      updatePetStatus: "petRecord/updatePetStatus",
      getTransitions: "petRecord/getTransitions",
    }),

    openPetStatusModals(pet) {
      this.petforPetStatus = pet;
      console.log(this.petforPetStatus);
      this.$store.commit("petStatusModals", true);
    },
    closePetStatusModals() {
      this.$store.commit("petStatusModals", false);
    },

    ddfalse() {
      this.dropdown = false;
    },

    async getStatus(value) {
      //this.$emit("current_status", value);

      this.petu.current_status = value;
      console.log(
        "-------this.petu.current_status---------- : ",
        this.petu.current_status
      );

      this.getTransitions(this.petu.current_status);
      console.log("allowedPetRecordStatusTransition 2 : ", this.petu.callName);
    },

    goTo(page, pet) {
      localStorage.setItem("pet", JSON.stringify(pet));

      this.$router.push({ name: page, params: { data: pet } });
    },

    async routeTo(pet) {
      // Put the pet object into storage
      localStorage.setItem("pet", JSON.stringify(pet));

      console.log("pet : ", pet);
      let v = "";
      const petSpices = this.petu.species;

      v = "PetRecord";

      this.$router.push({ name: v, params: { pet: pet } });
    },

    async submitForm(pet) {
      const email = localStorage.getItem("email");
      //this.closePetStatusModals();

      if (this.petStatusDropdownComp.trans == 218) {
        this.modalsConfirmPetStatus = true;
        this.modalTitle = "Was your pet found?";
        this.modalBody =
          "Please clear “lost” status only if your pet was found or to remove its listing from the lost pets database.";
        //console.log("show modal");
      } else if (
        this.petStatusDropdownComp.trans == 200 ||
        this.petStatusDropdownComp.trans == 201
      ) {
        this.modalsConfirmPetStatus = true;
        this.modalTitle = "Your pet has a new home?";
        this.modalBody =
          "If not, you can clear its status. Otherwise, select confirm to transfer the pet record to the new owner.";
        //console.log("show modal");
      } else if (
        this.petStatusDropdownComp.trans == 211 ||
        this.petStatusDropdownComp.trans == 217 ||
        this.petStatusDropdownComp.trans == 220 ||
        this.petStatusDropdownComp.trans == 221 ||
        this.petStatusDropdownComp.trans == 222 ||
        this.petStatusDropdownComp.trans == 223 ||
        this.petStatusDropdownComp.trans == 224 ||
        this.petStatusDropdownComp.trans == 226 ||
        this.petStatusDropdownComp.trans == 227 ||
        this.petStatusDropdownComp.trans == 229
      ) {
        console.log("--------------------- + --------------");

        /*  this.transitions.forEach((tr) => {
          var arr = [211, 217, 220, 221, 222, 223, 224, 226, 227, 229];
          var check = arr.includes(tr.id);
          if (check) this.transitionId = tr.id;
          console.log(this.transitionId);
        }); */

        this.transitionId = this.petStatusDropdownComp.trans;
        this.modalsPetDeath = true;

        console.log(this.modalsPetDeath);
        //this.$emit("close");

        console.log("close modal");
      } else {
        const data = {
          trans: this.petStatusDropdownComp.trans,
          petID: pet.petID,
          email: email,
        };

        this.updatePetStatus(data);

        this.getTransitions(this.petStatusDropdownComp.statusId);

        this.$emit("current_status", this.statusId);
        // this.modalsPetDeath = true;
        this.$emit("close");
      }
    },
  },
};
</script>

<style></style>
