<template>
  <div class="mt-2 mb-6 md:my-0 flex flex-col lg:flex-row items-start">
    <SectionLeft class="hidden lg:block" />
    <div class="h-full md:h-screen w-full lg:w-6/12 px-4 lg:pr-32">
      <PetoverviewSection />
      <img
        class="h-28 mt-8 md:mt-0 mb-4 md:mb-8 lg:hidden m-auto"
        src="@/assets/images/dataservice-logo.svg"
        alt="dataservice-logo"
      />
    </div>
    <NavMenuSecondary page="petoverview" />
    <!-- <DataserviceLogo /> -->
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import PetoverviewSection from "../components/petoverview/PetoverviewSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue";
import { getProgress } from "../services/commonService";

export default {
  components: {
    SectionLeft,
    PetoverviewSection,
    DataserviceLogo,
    NavMenuSecondary,
  },
  data() {
    return {
      progress: "",
      detailsProgress: "",
    };
  },
  async created() {
    const { data: progress } = await getProgress();
    //console.log(progress);
    this.progress = progress.progress;
    this.detailsProgress = progress.details;

    /*  if (this.progress == 100) {
      return true
    } else {
      this.$router.push('/onboardingdashboard')
    } */
  },
  methods: {},
};
</script>
